










































































































import Vue from 'vue';
import baseTable from '../components/baseTable.vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import {
  deleteQyStlBillDe,
  qyStlBillDe,
} from '@/api/costQuotaLibrary/costLibrary';
import { checkUserIdentity } from '../index';
import { mapGetters, mapMutations } from 'vuex';
const columns = [
  {
    title: '清单编码',
    key: 'bdCode',
  },
  {
    title: '项目名称',
    key: 'bdName',
  },
  {
    title: '单位',
    key: 'unit',
  },
  {
    title: '综合单价(元)',
    key: 'priceRange',
  },
  {
    title: '组价方案',
    key: 'planQuantity',
  },
  // {
  //   title: '备注',
  //   key: 'bz',
  // },
];
const actionList = [];
export default Vue.extend({
  name: 'costList',
  components: {
    baseTable,
    batchImport: () => import('./batchImport.vue'),
    moveClassify: () => import('../projectTree/moveClassify.vue'),
    // updateFile: () => import('./updateFile.vue'),
  },
  props: {},
  data() {
    return {
      openFileDialog: false,
      columns: Object.freeze(columns),
      actionList: Object.freeze(actionList),
      tableData: [],
      selectedRowKeys: [],
      fieldSlots: Object.freeze(['bdCode', 'planQuantity']),
      searchForm: {
        bdName: '',
        bdCode: '',
        majorNbr: '',
        page: 1,
        limit: 10,
      },
      importVisible: false,
      moveVisible: false,
      uploadFileUrl: '',
      selectedRows: [],
      templateUrl:
        'https://hzjt-yunzaojia-dev.oss-cn-beijing.aliyuncs.com/enterprise-quota/import-template/定额模板.xlsx',
      deleteVisible: false,
      isShowDeleteTips: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  watch: {
    'currentMajorInfo.sequenceNbr': function (val, oldVal) {
      if (val && val !== oldVal) {
        this.searchForm = {
          ...this.searchForm,
          majorNbr: val,
          bdCode: '',
          bdName: '',
        };
        this.init();
      }
    },
    importVisible: function (val) {
      if (!val) {
        this.uploadFileUrl = '';
      }
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'currentMajorInfo']),
    rowSelection(): { selectedRowKeys: string[]; onChange } {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
          this.selectedRowKeys = selectedRowKeys;
        },
      };
    },
  },
  mounted() {
    this.searchForm.majorNbr = this.currentMajorInfo?.sequenceNbr;
    // this.searchForm.majorNbr && this.qyStlBillDe();
  },
  activated() {
    this.searchForm.majorNbr && this.qyStlBillDe();
  },
  methods: {
    reset() {
      this.searchForm = {
        bdName: '',
        bdCode: '',
        majorNbr: this.currentMajorInfo?.sequenceNbr,
        page: 1,
        limit: 10,
      };
      this.qyStlBillDe();
    },
    importHandler() {
      this.openFileDialog = checkUserIdentity(this);
    },
    uploadFileCallback(url: string) {
      if (!url) {
        this.$message.error('上传失败');
      }
      this.uploadFileUrl = url;
      this.importVisible = true;
    },
    goDetail(record) {
      console.log('costList.vue|91====>', 'record', record);
      const currentCostInfo = {
        ...record,
        majorNbr: this.currentMajorInfo?.sequenceNbr,
      };
      sessionStorage.setItem(
        'currentCostInfo',
        JSON.stringify(currentCostInfo)
      );
      this.$router.push({
        path: '/ysf/costQuotaLibrary/detail',
      });
    },
    actionHandler({ record, key }) {
      if (key === TableActionType.VIEW) {
        this.goDetail(record);
      }
    },
    addHandler() {
      if (!checkUserIdentity(this)) return;
      this.$router.push('/ysf/costQuotaLibrary/add');
    },
    moveHandler() {
      if (!checkUserIdentity(this)) return;
      this.moveVisible = true;
    },
    qyStlBillDe() {
      this.selectedRowKeys = [];
      this.searchForm.page = this.pagination.current;
      this.searchForm.limit = this.pagination.pageSize;
      qyStlBillDe(this.searchForm).then((res: any) => {
        if (res.status === 200 && res.result) {
          res.result.list.forEach((item, index) => {
            item.id = this.searchForm.page + '-' + index;
          });
          this.tableData = res.result.list;
          this.pagination.total = res.result.total;
          this.selectedRows = [];
        }
      });
    },
    deleteQyStlBillDe() {
      const specifyList = this.getSpecifyList(this.selectedRows);
      console.log('this.se', specifyList);
      deleteQyStlBillDe(specifyList).then((res) => {
        if (res.status === 200 && res.result) {
          this.$message.success('批量删除成功');
          this.deleteCancel();
          this.init();
        }
      });
    },
    getSpecifyList(data) {
      return data.map((item) => {
        const { bdCode, bdName, unit } = item;
        return {
          bdCode,
          bdName,
          unit,
          majorNbr: this.searchForm.majorNbr,
        };
      });
    },
    deleteCancel() {
      this.deleteVisible = false;
    },
    deleteHandler() {
      if (!checkUserIdentity(this)) return;
      this.isShowDeleteTips = this.selectedRows.some(
        (item: any) => item.planQuantity > 0
      );
      this.deleteVisible = true;
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
      };
      this.qyStlBillDe();
    },
    init() {
      this.pagination = {
        ...this.pagination,
        current: 1,
        pageSize: 10,
      };
      this.qyStlBillDe();
    },
  },
});
