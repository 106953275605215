
















import Vue from 'vue';
import leftSide from './projectTree/index.vue';
import costList from './costLibrary/costList.vue';
import authGuide from './authGuide.vue';
export default Vue.extend({
  name: 'costQuotaLibrary',
  components: { leftSide, costList, authGuide },
  props: {},
  data() {
    return {};
  },
  methods: {},
});
